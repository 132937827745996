<template>
  <v-container fluid>
    <v-row align="center" justify="center">
       <v-col cols="12" sm="4">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> 12  </h3>
          <p class="pa-3 primary--text"> Total Contacts </p>
          <v-btn text class="pb-4" to="/contacts"> View More </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> 1  </h3>
          <p class="pa-3 primary--text"> Total Chats </p>
          <v-btn text class="pb-4" to="/chats"> View More </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> 10  </h3>
          <p class="pa-3 primary--text"> Total Messages </p>
          <v-btn text class="pb-4" to="/messages"> View More </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    loading: true
  })
}
</script>
